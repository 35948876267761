import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ArticleDO} from '../datamodel/article-do';
import {environment} from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FrbcSharedService {

  private youtubeUrlREquest = 'https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails';
  public youtubeApiKey = environment.apiKey;
  private playListId = 'PLFmFVZJvwZsSIYutg-BmfRh3q900ghixT';
  private lastSongPlayListId = 'PLFmFVZJvwZsSOeU9vVWwN2R5WJPBHqck1';

  constructor(private http: HttpClient) {
  }

  public getArticles(): Observable<any> {
    return this.http.get('/assets/json/threearticles.json');
    // return this.http.get('/threearticles.json');
    // '../../../assets/text.json'
  }

  public getSermons(): Observable<any> {
    return this.http.get('/assets/json/sermons.json');
    // return this.http.get('/sermons.json');
  }

  private calculateDayOfTheYear(): number {
    const now: any = new Date();
    const start: any = new Date(now.getFullYear(), 0, 0);
    const diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  }

  public getPrayingList(): Observable<any> {
    return this.http.get('assets/json/prayinglist.json');
    // return this.http.get('/prayinglist.json');
}

  // public uploadService(service: ServicesDO): Observable<ServicesDO> {
  //  return this.http.post<ServicesDO>('../../../assets/services.json', service);
  // }


  getStartDates(): Observable<any> {
      return this.http.get('assets/json/start.json');
     //return this.http.get('/start.json');
  }

  public getLastSong(): Observable<any> {
    return this.http.get(`${this.youtubeUrlREquest}&playlistId=${this.lastSongPlayListId}&key=${this.youtubeApiKey}`);
  }
}
